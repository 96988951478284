import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {Storage} from '@ionic/storage';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginPageModule'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'login-splash',
    loadChildren: './login-splash/login-splash.module#LoginSplashPageModule'
  },
  {
    path: 'forgot-password',
    loadChildren: './forgot-password/forgot-password.module#ForgotPasswordPageModule'
  },
  {
    path: 'reward-detail/:reward_id',
    loadChildren: './reward-detail/reward-detail.module#RewardDetailPageModule'
  },
  {
    path: 'post-detail/:post_id',
    loadChildren: './post-detail/post-detail.module#PostDetailPageModule'
  },
  {
    path: 'posts-overview',
    loadChildren: './posts-overview/posts-overview.module#PostsOverviewPageModule'
  },
  {
    path: 'rewards-overview',
    loadChildren: './rewards-overview/rewards-overview.module#RewardsOverviewPageModule'
  },
  {
    path: 'points-overview',
    loadChildren: './points-overview/points-overview.module#PointsOverviewPageModule'
  },
  {
    path: 'users-overview',
    loadChildren: './users-overview/users-overview.module#UsersOverviewPageModule'
  },
  {
    path: 'user-detail/:user_id',
    loadChildren: './user-detail/user-detail.module#UserDetailPageModule'
  },
  {
    path: 'events-overview',
    loadChildren: './events-overview/events-overview.module#EventsOverviewPageModule'
  },
  {
    path: 'event-detail/:event_id',
    loadChildren: './event-detail/event-detail.module#EventDetailPageModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private storage: Storage) {
  }
}
