import {Component} from '@angular/core';

import {NavController, Platform} from '@ionic/angular';
import {NgZone} from '@angular/core';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Storage} from '@ionic/storage';
import {LoadingController} from '@ionic/angular';
import {Events} from '@ionic/angular';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {OneSignal} from '@ionic-native/onesignal/ngx';

import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Dashboard',
      url: '/home',
      icon: 'stats'
    },
    {
      title: 'Nieuws',
      url: '/posts-overview',
      icon: 'paper'
    },
    {
      title: 'Kudo\'s',
      url: '/points-overview',
      icon: 'add'
    },
    {
      title: 'Rewards',
      url: '/rewards-overview',
      icon: 'pricetag'
    },
    {
      title: 'SAM\'s',
      url: '/users-overview',
      icon: 'contacts'
    },
    {
      title: 'Evenementen',
      url: '/events-overview',
      icon: 'calendar'
    },
  ];
  public showSplitPane = false;
  private loader;
  private loaderShowing = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private loadingController: LoadingController,
    private events: Events,
    private navController: NavController,
    private screenOrientation: ScreenOrientation,
    private oneSignal: OneSignal,
    private zone: NgZone
  ) {
    registerLocaleData(localeNl, 'nl-NL');
    this.initializeApp();

    this.events.unsubscribe('loader:present');
    this.events.unsubscribe('loader:dismiss');
    this.events.subscribe('loader:present', () => {
      this.presentLoader();
    });
    this.events.subscribe('loader:dismiss', () => {
      this.dismissLoader();
    });

    this.events.unsubscribe('splitpane:show');
    this.events.unsubscribe('splitpane:hide');
    this.events.subscribe('splitpane:show', () => {
      this.showSplitPane = true;
    });
    this.events.subscribe('splitpane:hide', () => {
      this.showSplitPane = false;
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('ios')) {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      }

      if (this.platform.is('android') || this.platform.is('ios')) {
        this.oneSignal.startInit('0bfdd85c-9279-4b13-8f4a-fc77b287a066', '1062599537716');

        this.oneSignal.handleNotificationOpened().subscribe((data) => {

          if (typeof data.notification.payload.additionalData.eventId !== 'undefined') {

            this.zone.run( () => {
              this.navController.navigateForward('/event-detail/' + data.notification.payload.additionalData.eventId);
            });
          }

          if (typeof data.notification.payload.additionalData.action !== 'undefined') {
            if (data.notification.payload.additionalData.action === 'showPointsOverview') {
              this.zone.run( () => {
                this.navController.navigateForward('/points-overview');
              });
            }
          }
        });

        this.oneSignal.endInit();
      }

      this.statusBar.styleLightContent();
      this.splashScreen.hide();
    });
  }

  logout() {
    this.storage.remove('api_key').then(() => {
      this.oneSignal.sendTag('id', '0');
      this.navController.navigateRoot('/login');
    });
  }

  async presentLoader() {
    this.loader = await this.loadingController.create({
      message: 'Laden...'
    });
    this.loader.present().then();
  }

  async dismissLoader() {
    if (typeof this.loader !== 'undefined') {
      this.loader.dismiss();
    }
  }
}
